import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { StoreHeader } from '../../../../components/headers'
import {Button, Input} from '../../../../components/UI';
import * as colors from '../../../../common/constants/colors'
import { userRegistration } from '../../../../common/store/reducers/user';
import { useDispatch } from 'react-redux';
import { validateValue } from '../../../../common/helpers';
import { validateEmail, validatePassword, validatePhone, validateRequiredString } from '../../../../common/helpers/validation';

const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin: 50px 0;
`;

const Title = styled.h1`
font-size: 42px;
font-weight: 600;
padding-top: 30px;
`;

const Form = styled.form`
padding-top: 95px;
width: 590px;
`;

const InputZone = styled.div`
width: 100%;
box-sizing: border-box;
padding-bottom: 66px;
`;

const Label = styled.div`
font-size: 18px;
font-weight: 600;
padding-bottom: 40px; 
`;

const ForgorPassword = styled.div`
margin-top: 24px;
> * {
  text-decoration: none;
  color: ${colors.BASE_BLUE}
}
`;

const ButtonsGroup = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const SignUpButtonText = styled.span`
font-size: 20px;
font-weight: 600;
`;

const CreateAccount = styled.div`
margin-top: 43px;
> * {
  font-size: 28px;
  font-weight: 600;
  text-decoration: none;
  color: ${colors.BASE_BLUE}
}
`;

const InputToggleButton = styled.div`
position: absolute;
top: 0px;
right: 0px;
padding: 12px 20px;
`;

const ErrorLabel = styled.div`
color: red;
font-weight: 500;
`;

const TermsAndPolicy = styled.div`
margin-bottom: 15px;
`;

const SpecialDescripion = styled.div`
margin: 10px 15px;
`;

function SignUp() {
  const dispatch = useDispatch();

  const [validating, setValidatingProcess] = useState(false);

  const [first_name, setF_name] = useState('');
  const [f_nameError, setF_nameError] = useState('');

  const [last_name, setL_name] = useState('');
  const [l_nameError, setL_nameError] = useState('');

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [passwordType, setPasswordType] = useState('password');



  const showPassword = (e: any) => {
    e.preventDefault();
    setPasswordType(passwordType === 'password' ? 'text' : 'password')
  }

  useEffect(()=>{setEmailError('')}, [email]);
  useEffect(()=>{setPasswordError('')}, [password]);
  useEffect(()=>{setF_nameError('')}, [first_name]);
  useEffect(()=>{setL_nameError('')}, [last_name]);
  useEffect(()=>{setPhoneError('')}, [phone]);


  const signUp = async (e: any) => {
    e.preventDefault();
    setValidatingProcess(true)
    await validateValue(first_name, validateRequiredString, (err)=>setF_nameError(err));
    await validateValue(last_name, validateRequiredString, (err)=>setL_nameError(err));
    await validateValue(phone, validatePhone, (err)=>setPhoneError(err));
    await validateValue(email, validateEmail, (err)=>setEmailError(err));
    await validateValue(password, validatePassword, (err)=>setPasswordError(err));
    setValidatingProcess(false)

    if (
      !emailError && 
      !passwordError && 
      !phoneError && 
      !l_nameError && 
      !f_nameError &&
      !validating
      ){
      dispatch(userRegistration({email, password, first_name, last_name, phone}))
    }
  }

  return (
    <>
    <Wrapper>
      <Title>Create Account</Title>
      <Form onSubmit={signUp}>
        <InputZone>
          <Label>First Name</Label>
          <Input placeholder={'First Name'} onChange={(e)=>{setF_name(e.target.value)}}/>
          <SpecialDescripion>
            <ErrorLabel>{`${f_nameError}`}</ErrorLabel>
          </SpecialDescripion>
        </InputZone>
        <InputZone>
          <Label>Last name</Label>
          <Input placeholder={'Last name'} onChange={(e)=>{setL_name(e.target.value)}}/>
          <ErrorLabel>{`${l_nameError}`}</ErrorLabel>
        </InputZone>
        <InputZone>
          <Label>Email</Label>
          <Input placeholder={'Email'} onChange={(e)=>{setEmail(e.target.value)}}/>
          <SpecialDescripion>
            <ErrorLabel>{`${emailError}`}</ErrorLabel>
          </SpecialDescripion>
        </InputZone>
        <InputZone>
          <Label>Phone number</Label>
          <Input placeholder={'Phone number'} onChange={(e)=>{setPhone(e.target.value)}}/>
          <SpecialDescripion>
            <ErrorLabel>{`${phoneError}`}</ErrorLabel>
          </SpecialDescripion>
        </InputZone>
        <InputZone>
          <Label>Password</Label>
          <div style={{position: 'relative'}}>
            <Input placeholder={'Password'} type={passwordType} onChange={(e)=>{setPassword(e.target.value)}}/>
            <InputToggleButton>
              <Button color={colors.BASE_GRAY} borders={false} variant={'transparent'} onClick={showPassword}>{passwordType === 'password' ? 'Show' : 'Hide'}</Button>
            </InputToggleButton>
          </div>
          <SpecialDescripion>
            {passwordError ? <ErrorLabel>{`${passwordError}`}</ErrorLabel> : <div>Password must be at least 6 characters</div>}
          </SpecialDescripion>
        </InputZone>
        <TermsAndPolicy>
          By clicking sign up you agree to the <Link to={'#'}>terms of use</Link> and <Link to={'#'}>privacy policy</Link>.
        </TermsAndPolicy>
        <ButtonsGroup>
          <Button margin-top={'115px'} magrin-bottom={'43px'} color={colors.BASE_BLUE} width='100%' height='85px' radiusValue={'43px'} onClick={signUp}>
            <SignUpButtonText>Continue</SignUpButtonText>
          </Button>
          <CreateAccount>
            <Link to={"/auth/signin"}>Sign in</Link>
          </CreateAccount>
        </ButtonsGroup>  
      </Form>
    </Wrapper>
    </>
  )
}

export default SignUp