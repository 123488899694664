import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import { Button } from '../UI';
import * as colors from '../../common/constants/colors'

function UserHeaderBlock(props: {user: any}) {
    const { user } = props
    
    return (
        <div>
            { user.isAuth ? 
                <>{user.first_name} {user.last_name}</> 
                : 
                <Link to={'/auth/signin'}>
                    <Button color={colors.BASE_BLUE} width='def'>Sign In</Button>
                </Link>}
        </div>
  )
}

export default UserHeaderBlock