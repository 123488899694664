import React from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Logo = styled.h1`
margin-left: 24px;
font-size: 24px;
font-weight: 700;
font-style: normal;
letter-spacing: normal;
line-height: normal;
text-align: left;
> a {
  text-decoration: none;
  color: inherit;
}
`;

function MainLogo() {
  return (
    <Logo>
        <Link to={'/'}>
            Logo
        </Link>
    </Logo>
  )
}

export default MainLogo