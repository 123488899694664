export enum ROLE {
  BUYER = '1',
  SELLER = '2',
  ADMIN = '4',
}

export enum RESTRICTED_ACTION {
  'seller' = 'seller',
  'buyer' = 'buyer',
  'admin' = 'admin',
  'guest' = 'guest',
}

const ADMIN_AVAILABLE_ACTIONS: Array<RESTRICTED_ACTION> = [
  RESTRICTED_ACTION.admin,
];

const SELLER_AVAILABLE_ACTIONS: Array<RESTRICTED_ACTION> = [
  RESTRICTED_ACTION.seller,
];

const BUYER_AVAILABLE_ACTIONS: Array<RESTRICTED_ACTION> = [
  RESTRICTED_ACTION.buyer,
];

export const ROLE_ACTIONS_MAP = {
  [ROLE.ADMIN]: ADMIN_AVAILABLE_ACTIONS,
  [ROLE.SELLER]: SELLER_AVAILABLE_ACTIONS,
  [ROLE.BUYER]: BUYER_AVAILABLE_ACTIONS,
};

