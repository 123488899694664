import Actions from '../saga/actionTypes'
import { api } from '../../services/api.service';
import {AxiosResponse} from 'axios';

export interface ISubs {
    isAuth: boolean,
    id? : number,
    first_name: string,
    last_name: string,
    email: string,
    permissions: object

}

const defaultState: {
    seller?: ISubs | null,
    buyer?: ISubs | null,
} = {
    seller: null,
    buyer: null
}

export default function subscriptionPlansReducer(state = defaultState, action: any) {
    switch(action.type) {
        case Actions.SET_SELLER_PLANS:{         
            return {...state, sellet: action.data}
        }
}
    return state
}

export const getSellerPlans = () => ({type: Actions.GET_SELLER_PLANS});
export const setSellerPlans = (data:{}) => ({type: Actions.SET_SELLER_PLANS, data});
