import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Link, useSearchParams } from "react-router-dom";
import { StoreHeader } from '../../../../components/headers'
import {Button, Input} from '../../../../components/UI';
import * as colors from '../../../../common/constants/colors'
import { userLogin } from '../../../../common/store/reducers/user';
import { useDispatch } from 'react-redux';
import { validateValue } from '../../../../common/helpers';
import { validateEmail, validatePassword } from '../../../../common/helpers/validation';


const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const Title = styled.h1`
font-size: 42px;
font-weight: 600;
padding-top: 30px;
`;

const Form = styled.form`
padding-top: 95px;
width: 590px;
`;

const InputZone = styled.div`
width: 100%;
box-sizing: border-box;
padding-bottom: 66px;
`;

const Label = styled.div`
font-size: 18px;
font-weight: 600;
padding-bottom: 40px; 
`;

const ErrorLabel = styled.div`
color: red;
margin: 10px 15px;
font-weight: 500;
`

const ForgorPassword = styled.div`
margin-top: 24px;
> * {
  text-decoration: none;
  color: ${colors.BASE_BLUE}
}
`;

const ButtonsGroup = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const SignInButtonText = styled.span`
font-size: 20px;
font-weight: 600;
`;

const CreateAccount = styled.div`
margin-top: 43px;
> * {
  font-size: 28px;
  font-weight: 600;
  text-decoration: none;
  color: ${colors.BASE_BLUE}
}
`;

const InputToggleButton = styled.div`
position: absolute;
top: 0px;
right: 0px;
padding: 12px 20px;
`;

function SignIn() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [params, setParams] = useSearchParams();
  
  const redirectUrl = params.get('redirect');

  useEffect(()=>{setEmailError('')}, [email]);
  useEffect(()=>{setPasswordError('')}, [password]);

  const showPassword = (e: any) => {
    e.preventDefault();
    setPasswordType(passwordType === 'password' ? 'text' : 'password')
  }

  const signIn = async (e: any): Promise<void> => {
    e.preventDefault();
    await validateValue(email, validateEmail, (err)=>setEmailError(err));
    await validateValue(password, validatePassword, (err)=>setPasswordError(err));

    if (!emailError && !passwordError){
      dispatch(userLogin({email, password, redirectTo: redirectUrl}))
    }
  }

  return (
    <>
    {/* <StoreHeader/> */}
    <Wrapper>
      <Title>Sign in</Title>
      <Form onSubmit={signIn}>
        <InputZone>
          <Label>Email or Phone number</Label>
          <Input placeholder={'Email'} onChange={(e)=>{setEmail(e.target.value)}}/>
          <ErrorLabel>{`${emailError}`}</ErrorLabel>
        </InputZone>
        <InputZone>
          <Label>Password</Label>
          <div style={{position: 'relative'}}>
            <Input placeholder={'Password'} type={passwordType} onChange={(e)=>{setPassword(e.target.value)}}/>
            <InputToggleButton>
              <Button color={colors.BASE_GRAY} borders={false} variant={'transparent'} onClick={showPassword}>{passwordType === 'password' ? 'Show' : 'Hide'}</Button>
            </InputToggleButton>
          </div>
          <ErrorLabel>{`${passwordError}`}</ErrorLabel>
        <ForgorPassword>
          <Link to={"/auth/restore"}>Forgot password?</Link>
        </ForgorPassword>
        </InputZone>
        <ButtonsGroup>
          <Button margin-top={'115px'} magrin-bottom={'43px'} color={colors.BASE_BLUE} width='100%' height='85px' radiusValue={'43px'} onClick={signIn}>
            <SignInButtonText>Sign In</SignInButtonText>
          </Button>
          <CreateAccount>
            <Link to={`/auth/signup${redirectUrl? `?redirect=${redirectUrl}`: ''}`}>Create account</Link>
          </CreateAccount>
        </ButtonsGroup>  
      </Form>
    </Wrapper>
    </>
  )
}

export default SignIn