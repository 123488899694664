export const LAYOUT_BACKGROUND = '#ffffff';

export const BASE_BLACK = '#000000';

export const BASE_GRAY = '#5f6369';

export const BASE_GRAY_BACKGROUND = '#f2f2f2';

export const BASE_BLUE = '#008bff';

export const BASE_GREEN = '#128a09';

export const FOOTER_BASE_GRAY = '#707070';

export const FOOTER_BACKGROUND = '#f5f5f7';

export const BASE_RED = '#ff4848';

export const INPUT_BORDERS = '#d3d3d3';
