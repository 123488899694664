import {applyMiddleware, combineReducers} from "redux";
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {rootWatcher} from "../saga"
import userReducer from "./user";
import redirectReducer from "./redirect";
import errorsReducer from "./errors";
import subscriptionPlansReducer from "./subscriptionPlans";

const sagaMiddleware = createSagaMiddleware()

const rootReducer = combineReducers({
 user: userReducer,
 redirect: redirectReducer,
 errors: errorsReducer,
 subs: subscriptionPlansReducer,

})

export const store = configureStore({reducer: rootReducer, middleware:[sagaMiddleware]})
export type RootState = ReturnType<typeof rootReducer>
sagaMiddleware.run(rootWatcher)