import {all} from "redux-saga/effects"
import {loginWatcher, userWatcher, registrationWatcher, restorePasswordWatcher} from "./user";

export function* rootWatcher() {
    yield all([
        userWatcher(),
        loginWatcher(),
        registrationWatcher(),
        restorePasswordWatcher(),
    ])
}