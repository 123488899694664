import React from 'react';
import styled from 'styled-components';
import * as colors from '../../common/constants/colors'
import * as sizes from '../../common/constants/sizes' 
import { LargeUserImageText } from '../userImages';
import { Button } from '../UI/Button';
import { StoreLogoProfile } from '../UI/StoreLogo/StoreLogoProfile';

const ShopBanner = styled.div`
height: 300px;
background-image: url(/temp_img/IMG_3887.png);
background-size: fill;
background-position: center
`;

const ShopDetails = styled.div`
display:flex;
justify-content: space-between;
margin: 85px 56px;
`;

const MainInfoWrapper = styled.div`
width: 690px;
display: flex;
justify-content: space-between;
`;

const InfoWrapper = styled.div`
display: flex;
`;

const ShopDescription = styled.div`
display: flex;
flex-direction: column;
justify-content: start;
margin-left: 28px;
> * {
  margin-bottom: 13px;
}`;

const AddressWrapper = styled.div`
display: flex;
`;

const AddressItem = styled.p`
margin-right: 10px;
font-size: 14px;
font-weight: 400;
color: ${colors.BASE_GRAY};
`;

const ShopName = styled.div`
font-size: 16px;
font-weight: 500;
`;

const UserInfo = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
`;



function StoreHeaderBussiness() {
  return (<>
    <ShopBanner/>
    <ShopDetails>
      <MainInfoWrapper>
        <InfoWrapper>
          <StoreLogoProfile></StoreLogoProfile>
          <ShopDescription>
            <ShopName>Name</ShopName>
            <AddressItem>Store type</AddressItem>
              <AddressWrapper>
              <AddressItem>Address</AddressItem>
              <AddressItem>|</AddressItem>
              <AddressItem>Location</AddressItem>
              <AddressItem>|</AddressItem>
              <AddressItem>On shopboon since 2022</AddressItem>
            </AddressWrapper>
            <div>
              <div>stars</div>
              <div>4.0</div>
            </div>
          </ShopDescription>
        </InfoWrapper>
        <Button variant='outlined' color={`${colors.BASE_BLUE}`}>Edit</Button>
      </MainInfoWrapper>
      <UserInfo>
        <LargeUserImageText>FL</LargeUserImageText>
        <ShopName>Name</ShopName>
      </UserInfo>
    </ShopDetails>
  </>
  )
}
export default StoreHeaderBussiness