import { FunctionComponent } from "react";
import AuthRoutes from "../../modules/auth/authRoutes";
import BuyerRoutes from "../../modules/buyer/buyerRoutes";
import InfoRoutes from "../../modules/info/infoRoutes";
import SellerRoutes from "../../modules/seller/sellerRoutes";
import { RoleMiddleware } from "../services/role.service";
import { RESTRICTED_ACTION } from "./roles";


interface Route {
    path: string;
    element: FunctionComponent | string;
    isAvailable: boolean;
  }

export const getRoutes = (): Array<Route> => [
    {
        path: '/auth/*',
        element: AuthRoutes,
        isAvailable: true,
    },
    {
        path: '/*',
        element: BuyerRoutes,
        isAvailable: true,
    },
    {
        path: '/start/*',
        element: InfoRoutes,
        isAvailable: true,
    },
    {
        path: '/seller/*',
        element: SellerRoutes,
        isAvailable: RoleMiddleware.checkIsAvailable(RESTRICTED_ACTION.seller),
    },
]