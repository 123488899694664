import React, { ButtonHTMLAttributes, ReactElement } from 'react'
import styled from 'styled-components';
import * as colors from '../../../common/constants/colors'

type ButtonVariant = 'contained' | 'outlined' | 'transparent';

interface StyledButtonProps {
  variant?: ButtonVariant;
  color?: string;
  fullWidth?: boolean;
  isLoading?: boolean;
  spinnerColor?: string;
  borderRadius?: boolean;
  width?: string;
  height?: string;
  radiusValue?: string;
  borders?: boolean;
}

const StyledButton = styled.button`
  padding: 16px 32px;
  border: none;
  background: ${({ variant, color }: StyledButtonProps) => (variant === 'contained' ? color : (variant === 'transparent'? 'rgba(0,0,0,0)' : '#ffffff'))};
  ${({variant}: StyledButtonProps)=> variant === 'outlined' ? 'box-shadow: 0px 5px 20px #00000029;' : ''}
  color: ${({ variant, color }: StyledButtonProps) => (variant === 'contained' ? '#ffffff' : color)};
  width: ${({ width }: StyledButtonProps) => (width ? width : 'auto')};
  height: ${({ height }: StyledButtonProps) => (height ? height : '25px')};
  cursor: pointer;
  font-size: inherit;
  border-radius: ${({ borderRadius, radiusValue }: StyledButtonProps) => (borderRadius ? (radiusValue ? radiusValue : '21px') : 'none')};
  transition: background-color 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  :focus {
    outline: none;
  }
`;

const Button = ({
  variant = 'contained',
  height = '42px', 
  width = '83px',
  color = `${colors.BASE_BLACK}`,
  borderRadius = true,
  radiusValue = '21px',
  fullWidth = true,
  isLoading = false,
  borders = true,
  children,
  ...props
}: StyledButtonProps & ButtonHTMLAttributes<HTMLButtonElement>): ReactElement => {
  return (
    <StyledButton borderRadius={borderRadius} borders={borders} width={width} height={height} variant={variant} color={color} radiusValue={radiusValue} {...props}>
      {<>{children}</>}
    </StyledButton>
  );
};

export default Button 