import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { redirectSuccess } from '../../store/reducers/redirect';

function RedirectsHelper(props:any) {
  const {active, to} = props;
  const dispatch = useDispatch();

  useEffect(()=>{
    if (active){
      dispatch(redirectSuccess());
  }
  }, [to])
  

  return (<>
    {active && to ? <Navigate to={`${to}`}/> : <></>}
  </>
  )
}

function mapStateToProps(state: any) {
  const { redirect } = state
  return { ...redirect }
}

export default connect(mapStateToProps)(RedirectsHelper)