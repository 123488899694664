import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import { DefaultHeader } from '../../../components/headers';
import { Restore, SignIn, SignUp } from '../pages';

function AuthRoutes() {
  return (<>
    
    <DefaultHeader/>
    <Routes>
      <Route path="signin" element={<SignIn/>}/>
      <Route path="signup" element={<SignUp/>}/>
      <Route path="restore" element={<Restore/>}/>

      <Route path="*" element={(<Navigate to="/"/>)}/>
    </Routes>
  </>
  )
}

export default AuthRoutes