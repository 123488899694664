import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Link } from 'react-router-dom'
import styled from 'styled-components';
import { Basket } from '../basket';
import { Search } from '../search';
import { UserHeaderBlock } from '../userHeaderBlock';
import DefaultHeader from './defaultHeader';
import {ImLocation2} from "react-icons/im";

const HeaderContentWrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
padding: 0 25px;

`;

const ZipCodeWrapper = styled.div`
box-shadow: 0px 3px 10px #00000029;
border-radius: 33px;
height: 58px;
width: 293px;
font-size: 17px;
display: flex;
align-items: center;
padding: 0 10px;
`;
const ZipCodeInput = styled.input`
opacity: 1;
outline: none;
border: none;
width: 100%;
padding: 0 10px;
font-size: inherit;
&::placeholder {
  color: inherit;
}
`;

const HeaderButtonsWrapper = styled.div`
display: flex;`;

const HeaderButton = styled.div`
margin: 30px;
&>a {
  text-decoration: none;
  color: inherit
}
`;

const UserBlockWrapper = styled.div`
display: flex;
&>* {
  margin: 0 5px;
}`;


function BuyerHeader(props: any) {
  const [zipCode, setZipCode] = useState('');

  const [user, setUser] = useState(props.user)

  useEffect(()=> {
    setUser(props.user)
  }, [props.user])

  return (<>
    <DefaultHeader>
        <HeaderContentWrapper>
          <ZipCodeWrapper>
            <ImLocation2/>
            <ZipCodeInput placeholder={'Enter zip code'} value={zipCode ? zipCode : void 0}onChange={(e)=>setZipCode(e.target.value)}/>
          </ZipCodeWrapper>
          <HeaderButtonsWrapper>
            <HeaderButton>
              <Link to={'/start/returns'}>Returns</Link>
            </HeaderButton>
            <HeaderButton>
              <Link to={'/start/selling'}>Start Selling</Link>
            </HeaderButton>
            <HeaderButton>
              <Link to={'/start/driving'}>Start Driving</Link>
            </HeaderButton>
          </HeaderButtonsWrapper>
          <UserBlockWrapper>
            <Search/>
            <Basket/>
            <UserHeaderBlock user={user}/>
          </UserBlockWrapper>  
        </HeaderContentWrapper>
    </DefaultHeader>
  </>
  )
}

function mapStateToProps(state: any) {
  const { user } = state
  return { user }
}

export default connect(mapStateToProps)(BuyerHeader)