import {put, takeEvery, call} from 'redux-saga/effects';
import Actions from './actionTypes'
import {setUser, getUser} from '../reducers/user';
import { redirect } from '../reducers/redirect';
import { api } from '../../services/api.service';
import {AxiosResponse} from 'axios';
import { RoleMiddleware } from '../../services/role.service';
import { ROLE } from '../../constants/roles';
import { setError } from '../reducers/errors';

interface Response extends AxiosResponse{
    message?: any
    accessToken: string
}

function* restorePasswordWorker(action: any) {
    const {restore_type, email, phone, code} = action
    const data:AxiosResponse = yield api.post(`/auth/restore-password/${restore_type}`, {email, phone, code})
    if(data.request?.status !== 0){
        if(data.request?.status !== 200){
            const respData = data.request.response
            yield put(setError((JSON.parse(respData)).message))
          //yield put(updateError({isActive: true, type:'danger', error:'Некорректный логин или пароль'}))
        }else{
            const userData = data.data
            if (userData.accessToken){
                //localStorage.setItem('token', userData.accessToken)
                //yield put(getUser(null))
                //yield put(redirect('/'))
            }
        }

    }else{
        //yield put(updateError({isActive: true, type:'danger', error:data.message}))

    }

}

function* getUserWorker(action:any) {
        const data:AxiosResponse = yield api.get('user/info')
        yield put(setUser({...data.data, isAuth: true}));
        RoleMiddleware.setRoles(data.data.roles as ROLE[])
}

function* userLoginWorker(action:any) {
    const data:AxiosResponse = yield api.post('auth/login', {...action.data})
    if(data.request?.status !== 0){
        if(data.request?.status !== 200){
            const respData = data.request.response
            yield put(setError((JSON.parse(respData)).message))
          //yield put(updateError({isActive: true, type:'danger', error:'Некорректный логин или пароль'}))
    
        }else{
            const userData = data.data
            localStorage.setItem('token', userData.accessToken)
            yield put(getUser(null))
            RoleMiddleware.setRoles(data.data.roles as ROLE[])
            yield put(redirect(action.data.redirectTo ? action.data.redirectTo : '/'))
        }

    }else{
        //yield put(updateError({isActive: true, type:'danger', error:data.message}))

    }
    
}

function* userRegistrationWorker(action:any) {
    const data:Response = yield api.post('/auth/registration', action.data)
    if(data.request?.status !== 0){
        if(data.request?.status !== 200){
            const respData = data.request.response
            yield put(setError((JSON.parse(respData)).message))
          //yield put(updateError({isActive: true, type:'danger', error:'Что то пошло не так'}))
    
        }else if(data.request?.status === 500){
          //yield put(updateError({isActive: true, type:'danger', error:'Упс. Внутренняя ошибка. Похоже, такой Email уже есть =)'}))

        }else{
            const userData = data.data
            localStorage.setItem('token', userData.accessToken)
            yield put(getUser(null))
            yield put(redirect(action.data.redirectTo ? action.data.redirectTo : '/'))
            //yield put(updateError({isActive: true, type:'info', error:'Регистрация успешно пройдена'}));

        }

    }else{
        //yield put(updateError({isActive: true, type:'danger', error:data.message}))

    }
}

export function* userWatcher() {
    yield takeEvery(Actions.GET_USER, getUserWorker)
} 
export function* loginWatcher() {
    yield takeEvery(Actions.USER_LOGIN, userLoginWorker)
}

export function* restorePasswordWatcher() {
    yield takeEvery(Actions.RESTORE_PASSwORD, restorePasswordWorker)
}

export function* registrationWatcher() {
    yield takeEvery(Actions.USER_REGISTRATION, userRegistrationWorker)
} 