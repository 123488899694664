import React from 'react'
import {FaInstagram, FaTwitter, FaFacebook, FaTiktok} from "react-icons/fa";
import styled from 'styled-components';

const SocialWrapper = styled.div`
width: 15%;
display: flex;
justify-content: space-between;
`

function SocialLinks() {
  return (
    <SocialWrapper>
      <FaFacebook size='32px'/>
      <FaTwitter size='32px'/>
      <FaInstagram size='32px'/>
      <FaTiktok size='32px'/>
    </SocialWrapper>
  )
}

export default SocialLinks