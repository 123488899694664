import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { getRoutes } from './common/constants';
import { useDispatch } from 'react-redux';
import { getUser } from './common/store/reducers/user';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import styled from 'styled-components'
import { APP_MAX_WIDTH } from './common/constants/sizes'
import { RedirectsHelper } from './common/helpers/redirects'
import { RoleMiddleware } from './common/services/role.service';
import { Footer } from './components/footer';
import { ErrorMessages } from './components/errorMessages';

require('helvatica-neue-lt/index.css');

const AppWrapper = styled.div`
max-width: ${APP_MAX_WIDTH}px;
min-height: 100vh;
margin: 0 auto;
`

function App() {
  const dispatch = useDispatch();

  const [routes, setRoutes] = useState(getRoutes());

  RoleMiddleware.addRolesWatcher(()=>{setRoutes(getRoutes())})

  useEffect(() => {
    const localToken = localStorage.getItem('token');

    if (localToken){
        dispatch(getUser(null))
    } 
  }, []);
  return (
    <AppWrapper>
      <Router>
        <RedirectsHelper/>
        <ErrorMessages/>

        {routes ? (
          <Routes>
          {getRoutes().filter(route => route.isAvailable).map((route, idx) =>
            <Route key={idx} path={route.path} element={<route.element/>}/>
          )}
        </Routes>
        ) : <></>}  
      </Router>
    </AppWrapper>
  );
}

export default App;
