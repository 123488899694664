import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as sizes from '../../common/constants/sizes'
import * as colors from '../../common/constants/colors'
import { connect, useDispatch } from 'react-redux';
import { removeError } from '../../common/store/reducers/errors';

const ErrorWrapper = styled.div`
position: absolute;
bottom: 150px;
max-width: ${sizes.APP_MAX_WIDTH}px;
width: -webkit-fill-available;
display: flex;
align-items: center;

flex-direction: column;
`;

const ErrorMessage = styled.div`
background-color: ${colors.BASE_RED}cf;
color: white;
font-size: 15pt;
width: fit-content;
padding: 25px;
border-radius: 20px;
border: 1px solid black;
`;

function ErrorMessages(props: any) {
    const [errors, setErrors] = useState([] as (string)[])
    const dispatch = useDispatch();

    useEffect(()=>{
        const parsedErrors: (string)[] = Object.values(props.errors);
        setErrors(parsedErrors);
        setTimeout(()=> {
            Object.keys(props.errors).forEach(key => dispatch(removeError(key)))
        }, 3500)

    }, [props.errors])

  return (
    <ErrorWrapper>
        {errors.map((message, idx) => <ErrorMessage key={idx}>{message}</ErrorMessage>)}
    </ErrorWrapper>
  )
}
function mapStateToProps(state: any) {
    const { errors } = state
    return { errors }
  }

export default connect(mapStateToProps)(ErrorMessages)