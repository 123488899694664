import React from 'react'
import styled from 'styled-components';
import {BsSearch} from "react-icons/bs";

const SearchWrapper = styled.div`
display: flex;
align-items:center;
justify-content: center;
`;

function Search() {
  return (
    <SearchWrapper>
      <BsSearch/>
    </SearchWrapper>
  )
}

export default Search