import React, { ReactElement } from 'react'
import styled from 'styled-components';

/* const StoreLogo = styled.div`
width: 150px;
height: 150px;
background-image: url(${image});
background-size: fill;
background-position: center;
border: 1px solid red;
`;

function StoreLogoProfile(props: {image: string}) {
  return (
    <StoreLogo image={image}/>
  )
}

export default StoreLogoProfile */
interface StoreLogoProfileProps {
    width?: string;
    height?: string;
    image? : string | null;
    updateLogoCB?: any;
  }
  
  const StyledButton = styled.div`
    width: ${({ width }: StoreLogoProfileProps) => (width ? width : '150px')};
    height: ${({ height }: StoreLogoProfileProps) => (height ? height : '150px')};
    background-image: url(${({ image }: StoreLogoProfileProps) => (image ? image : '/temp_img/base_store_logo.png')});
    position: relative
  `;

  const UpdatePhoto = styled.div`
  width: 56px;
  height: 42px;
  background-image: url(/temp_img/update_store_logo.svg);
  position: absolute;
  bottom: 0px;
  right: 0px;
  cursor: pointer;

  `;
  
  const StoreLogoProfile = ({
    height = '150px',
    width = '150px',
    image = null,
    updateLogoCB = () => {},
  }: StoreLogoProfileProps): ReactElement => {
    return (
      <StyledButton width={width} height={height} image={image} updateLogoCB={updateLogoCB}>
          <UpdatePhoto/>
      </StyledButton>
    );
  };
  
  export default StoreLogoProfile 