import React from 'react'
import { Button } from '../../../../components/UI'
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import * as colors from '../../../../common/constants/colors';

const Block = styled.div`
display: flex;
flex-direction:column;
align-items: center;
margin: 10px 0 100px 0;
`;

const WelcomeBlock = styled.div`
display: flex;
width: 100%;
justify-content: space-around;
aling-items: center;
font-size: 25px;
&>div>a{
  text-decoration: none;
}
&>div{
  width:50%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  aling-items: center;
}
`;

const WelcomeBlockTitle = styled.h1`
font-size: 42px;
font-weight: 900;
margin-top: 10px;
margin-bottom: 40px;
`;

const WelcomeBlockDesc = styled.h3`
font-size: 26px;
font-weight: 500;
color: ${colors.BASE_GRAY};
margin-bottom: 100px;
`;

const FullWidthImage = styled.img`
width:100%;
`;

const CardWrapper = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
`;

const Card = styled.div`
width: 400px;
background-color: #fff;
box-shadow: 0px 5px 20px #00000029;
border-radius: 20px;
display: flex;
flex-direction: column;
align-items: center;
padding: 30px;
box-sizing: border-box;
`;

const CardLogo = styled.div`
box-sizing: border-box;
margin-top: 34px;
`;

const CardLogoImage = styled.img``;

const CardTitle = styled.h3`
margin-top: 75px;
font-size: 24px;
font-weight: 500; 
`;

const CardDescription = styled.div`
margin-top: 63px;
text-align: center;
line-height: 26pt;
color: ${colors.BASE_GRAY};
font-size: 20px;
`;

const Title = styled.h3`
font-size: 32px;
font-weight: 500;
margin-top: 10px;
margin-bottom: 40px;
`;

const FAQ = styled.div`
display: flex;
flex-direction:column;
align-items: center;
justify-content: center;
`;

const FAQItem = styled.div`
text-align: center;
margin-bottom: 68px;
font-size: 24px;
width: 90%;
`;

const FAQItemTitle = styled.h3`
font-weight: 500;
margin-bottom: 37px;
`;

const FAQItemDescription = styled.div`
color: ${colors.BASE_GRAY}`;

const SellingPlansWrapper = styled.div`
display: flex;
width: 100%;
&>div{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &>hr{
    width: 100%;
  };
  &>a{
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
  }
}`

const PlanTitle = styled.h3`
font-size: 32px;
font-weight: 500;
margin: 25px
`;

const PlanPrice = styled.div`
margin: 55px 0;
font-size: 24px;
&>span{
  font-size: 42px;
  font-weight: 500;
}
`;

const PlanPriceFee = styled.div`
color: ${colors.BASE_GRAY};
text-align: center;
margin-top: 16px
`;

const PlanDesc = styled.ul`
list-style-type: disc;
padding: 25px 50px;
height: -webkit-fill-available;
display: flex;
flex-direction: column;
justify-content: center;
font-size: 18px;
color: ${colors.BASE_GRAY};
line-height: 55px;
`;

function BecomeSeller() {
  return (<>
  <Block>
    <WelcomeBlock>
      <div>
        <WelcomeBlockTitle>
          Start selling now on shopboon
        </WelcomeBlockTitle>
        <WelcomeBlockDesc>
          Put your products in front of new customers
        </WelcomeBlockDesc>
        <Link to={'/auth/signup'}>
          <Button color={colors.BASE_BLUE} width='381px' height='84px' radiusValue='50px' >Sign up</Button>
        </Link>
      </div>
      <div>
        <FullWidthImage src='/temp_img/image_2022-05-12_16-52-41.png'/>     
      </div>
    </WelcomeBlock>
  </Block>
  <Block>
    <FullWidthImage src='https://images.freeimages.com/images/large-previews/458/sunset-panorama-1168101.jpg'/>     
  </Block>
  <Block>
    <Title>How it works</Title>
    <CardWrapper>
    <Card>
        <CardLogo>
          <CardLogoImage width='150px' src='https://e7.pngegg.com/pngimages/612/568/png-clipart-business-plan-marketing-plan-business-people-plan.png'/>
        </CardLogo>
        <CardTitle>
          Choose a selling plan
        </CardTitle>
        <CardDescription> 
          Select a sellers plan. You have two options a business plan or an Individual plan. The business plan costs $34.99 per month with a 9% fee. The individual plan is no monthly fee just $ 0.99 every time you sell an item plus a 9% fee.
        </CardDescription>
      </Card>
      <Card>
        <CardLogo>
          <CardLogoImage width='150px' src='https://e7.pngegg.com/pngimages/612/568/png-clipart-business-plan-marketing-plan-business-people-plan.png'/>
        </CardLogo>
        <CardTitle>
          Create an account
        </CardTitle>
        <CardDescription>
          To create an account you can either use your customer account or create a new account. Only one sellers account allowed per Individual.
        </CardDescription>
      </Card>
      <Card>
        <CardLogo>
          <CardLogoImage width='150px' src='https://e7.pngegg.com/pngimages/612/568/png-clipart-business-plan-marketing-plan-business-people-plan.png'/>
        </CardLogo>
        <CardTitle>
          Add products
        </CardTitle>
        <CardDescription>
          To add products choose your products category and list your products. 
        </CardDescription>
      </Card>
    </CardWrapper>
  </Block>
  <Block>
    <Title>Selling plans</Title>
    <SellingPlansWrapper>
      <div>
        <PlanTitle>Buisness</PlanTitle>
        <hr/>
        <PlanPrice>
          <span>$ 34.99</span> / Month
          <PlanPriceFee>
            + 9% fee
          </PlanPriceFee>
        </PlanPrice>
        <hr/>
        <PlanDesc>
          <li>You don’t need to have a business to start a business account</li>
          <li>Selling more than 30 items a month</li>
          <li>10 listed items minimum</li>
          <li>Cancel plan anytime</li>
        </PlanDesc>
        <Link to='/auth/signup'>
          <Button variant='outlined' color={colors.BASE_BLUE} width='335px' height='66px'>Sign up for Business</Button>
        </Link>
      </div>
      <div>
        <PlanTitle>Individual</PlanTitle>
        <hr/>
        <PlanPrice>
          <span>$ 0.99</span> / per item sold
          <PlanPriceFee>
            + 9% fee
          </PlanPriceFee>
        </PlanPrice>
        <hr/>
        <PlanDesc>
          <li>Selling only a couple of products a month</li>
          <li>No monthly fees</li>
          <li>Cancel plan anytime</li>
        </PlanDesc>
        <Link to='/auth/signup'>
          <Button variant='contained' color={colors.BASE_BLUE} width='335px' height='66px'>Sign up for Individual</Button>
        </Link>
      </div>
    </SellingPlansWrapper>
  </Block>
  <Block>
    <Title>Frequently Asked Questions.</Title>
      <Title></Title>
    <FAQ>
      <FAQItem>
        <FAQItemTitle>How do I get paid?</FAQItemTitle>
        <FAQItemDescription>Funds will be direct deposited on a by-weekly basis.</FAQItemDescription>
      </FAQItem>
      <FAQItem>
        <FAQItemTitle>How do fees work?</FAQItemTitle>
        <FAQItemDescription>There are two selling fees, a transaction fee, and a payment processing fee. Once an Item sells there will then be a transaction fee, and a payment processing fee.</FAQItemDescription>
      </FAQItem>
      <FAQItem>
        <FAQItemTitle>What can I sell on shopboon?</FAQItemTitle>
        <FAQItemDescription>Selling a products on shopboon depends on what categories are available to choose from.</FAQItemDescription>
      </FAQItem>
      <FAQItem>
        <FAQItemTitle>What are restricted products on shopboon?</FAQItemTitle>
        <FAQItemDescription>Before Listing your products on shopboon, make sure the sale of your Items complies with all laws and any other additional restrictions applicable to payment service offered on shopboon such as credit card association.</FAQItemDescription>
      </FAQItem>
      <FAQItem>
        <FAQItemTitle>What is the difference between a business plan and an individual plan?</FAQItemTitle>
        <FAQItemDescription>A business plan is for business’s or individuals who are looking to sell more than 30 Items per month. An Individual plan is for those who are looking to sell only a few items per month. Choose whatever plan is best for your selling needs.</FAQItemDescription>
      </FAQItem>
      <FAQItem>
        <FAQItemTitle>Can I cancel my business plan anytime?</FAQItemTitle>
        <FAQItemDescription>You may cancel your business plan anytime before the next billing cycle.</FAQItemDescription>
      </FAQItem>
      <FAQItem>
        <FAQItemTitle>How do I fill out my taxes as a seller?</FAQItemTitle>
        <FAQItemDescription>For those who sell more than $20,000 in unadjusted gross sales, and more than 200 transactions will have to fill out Form 1099-K. If you don’t meet any of the criteria Form 1099-K doesn’t apply to you.</FAQItemDescription>
      </FAQItem>
    </FAQ>
  </Block>
  </>
  )
}

export default BecomeSeller