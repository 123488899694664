import Actions from '../saga/actionTypes'
import { api } from '../../services/api.service';
import {AxiosResponse} from 'axios';

export interface IUser {
    isAuth: boolean,
    id? : number,
    first_name: string,
    last_name: string,
    email: string,
    permissions: object

}

const defaultState: IUser = {
    isAuth: false,
    first_name: '',
    last_name: '',
    email: '',
    permissions: {},
}

export default function userReducer(state = defaultState, action: any) {
    switch(action.type) {
        case Actions.SET_USER:{
/*             if(!action.data.accessToken){
                state.isAuth = false
            } */
            
            return {...state, ...action.data}
        }
        case Actions.USER_LOGOUT:{
            localStorage.removeItem('token');
            api.post('/auth/logout');
            return {...defaultState, isAuth:false}
        }
        case Actions.USER_REGISTRATION:{
            //const userdata = api.post('/auth/registration', action.data);
            
            //console.log(action);
            //updateError({isActive: true, type:'info', error:'Регистрация успешно пройдена'})

        }
        
}
    return state
}

export const getUser = (id: string | null) => ({type: Actions.GET_USER, id});
export const setUser = (data:{}) => ({type: Actions.SET_USER, data});
export const userLogin = (data: object) => ({type: Actions.USER_LOGIN, data});
export const userLogout = () => ({type: Actions.USER_LOGOUT});
export const userRegistration = (data:{}) => ({type: Actions.USER_REGISTRATION, data});
export const userRestoreAccess = (restore_type: string, email?: string, phone?: string, code?: string) => ({type: Actions.RESTORE_PASSwORD, restore_type, email, phone, code})

