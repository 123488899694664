import React, { InputHTMLAttributes, ReactElement, HTMLInputTypeAttribute } from 'react'
import styled from 'styled-components';
import * as colors from '../../../common/constants/colors'

interface StyledInputProps {
  type?: HTMLInputTypeAttribute;
  color?: string;
  fullWidth?: boolean;
  borderRadius?: boolean;
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  children?: any;
  placeholder?: string;
}

const StyledInput = styled.input`
  padding: 21px 45px;
  width: ${({ width, fullWidth }: StyledInputProps) => (fullWidth ? '100%' : (width ? width : 'auto'))};
  height: ${({ height }: StyledInputProps) => (height ? height : 'auto')};
  cursor: text;
  font-size: 18px;
  outline: none;
  border: 1px solid ${colors.INPUT_BORDERS};
  border-radius: ${({ borderRadius }: StyledInputProps) => (borderRadius ? '21px' : 'none')};
  transition: background-color 0.3s ease-in-out;
  box-sizing: border-box;
  position: relative;

  :focus {
    outline: none;
  }
`;

const Input = ({
  type = 'text',
  height = 'auto',
  width = '83px',
  borderRadius = true,
  fullWidth = true,
  children,
  ...props
}: StyledInputProps & InputHTMLAttributes<HTMLInputElement>): ReactElement => {
  return (
    <StyledInput borderRadius={borderRadius} fullWidth={fullWidth} width={width} height={height} type={type} {...props}>
    </StyledInput>
  );
};

export default Input 