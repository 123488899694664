import React from 'react'
import { Routes, Route, Navigate} from 'react-router-dom';
import { Footer } from '../../../components/footer';
import { DefaultHeader } from '../../../components/headers';
import { BecomeSeller, BecomeDriver, Returns } from '../pages'

function InfoRoutes() {
  return (<>
    <DefaultHeader/>
    <Routes>
      <Route path="/selling" element={<BecomeSeller/>}/>
      <Route path="/driving" element={<BecomeDriver/>}/>
      <Route path="/returns" element={<Returns/>}/>

      <Route path="*" element={(<Navigate to="/"/>)}/>
    </Routes>
    <Footer/>
  </>
  )
}

export default InfoRoutes