import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

const API_URL = 'https://api.shopboon.app'
export const api = axios.create({
    //withCredentials: true,
    baseURL:API_URL
})

api.interceptors.request.use((config: AxiosRequestConfig)=>{
    const auth_token = localStorage.getItem('token');
    if (auth_token && config && config.headers){
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}` 
    }
    return config
})

api.interceptors.response.use((config: AxiosRequestConfig)=>{
    return config   
}, async (error)=>{
    const originalRequest = error.config;
    if (error.response?.status === 401 && error.config && !error.config._isRetry){
        originalRequest._isRetry=true
        try{
            const response:AxiosResponse = await axios.get(`${API_URL}/auth/refresh`, {
                withCredentials:true
            })

            localStorage.setItem('token', response.data.accessToken)
            return await api.request(originalRequest)
        }catch(e){
            localStorage.removeItem('token')
            window.location.reload()
        }
        
    }
    return error;
})

export class BaseService {

    login =(email: string, password: string):Promise<AxiosResponse<any>> => {
        return api.post('/auth/login', {email, password})
    }

    logout = async ():Promise<AxiosResponse<any>> => {
        return api.post('/auth/logout')
    }

    registration = async (data: object):Promise<AxiosResponse<any>> => {
        return api.post('/auth/registration', data)
    }
}
