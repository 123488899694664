import React from 'react'
import styled from 'styled-components';
import * as colors from '../../common/constants/colors';
import { SocialLinks } from '../socialLinks';
import { MainLogo } from '../UI/mainLogo';
import { Link } from 'react-router-dom'



const FooterWrapper = styled.div`
background-color: ${colors.FOOTER_BACKGROUND};
display: flex;
justify-content: space-between;
padding: 55px 25px;
color: ${colors.FOOTER_BASE_GRAY};
`;

const LinksWrapper = styled.div`
display: flex;
flex-direction: column;
&>a{
  text-decoration: none;
  color: ${colors.FOOTER_BASE_GRAY};
  margin-bottom: 25px;
}
`;

function Footer() {
  return (
    <FooterWrapper>
      <MainLogo/>
      <LinksWrapper>
        <Link to='/'>Who we are</Link>
        <Link to='/auth/signup'>Sign up</Link>
        <Link to='/start/selling'>Start Selling</Link>
        <Link to='/start/driving'>Start Driving</Link>
        <Link to='/start/returns'>Returns</Link>
        <Link to='/'>Terms</Link>
        <Link to='/'>Help</Link>
      </LinksWrapper>
      <SocialLinks/>
    </FooterWrapper>
  )
}

export default Footer