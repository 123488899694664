import React from 'react';
import styled from 'styled-components';
import * as color from '../../common/constants/colors'
import * as sizes from '../../common/constants/sizes' 
import { LargeUserImageText } from '../userImages';
import * as colors from '../../common/constants'
import { Button } from '../UI/Button';

const ShopDetails = styled.div`
display:flex;
max-width: 668px;
justify-content: space-between;
margin: 48px auto;
`
const MainInfoWrapper = styled.div`
display: flex;
margin-top: 25px;
`;

const InfoWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
margin: 15px 0px 15px 28px;
`;

const AddressWrapper = styled.div`
display: flex;
color: ${colors.BASE_GRAY};
`;

const AddressItem = styled.p`
margin-right: 10px;
font-size: 14px;
font-weight: 400;
`;

const ShopName = styled.div`
font-size: 16px;
font-weight: 500;
`;


function StoreHeaderIndividual() {
  return (<>
    <ShopDetails>
        <MainInfoWrapper>
          <LargeUserImageText>FL</LargeUserImageText>
          <InfoWrapper>
            <ShopName>Name</ShopName>
            <AddressWrapper>
              <AddressItem>Address</AddressItem>
              <AddressItem>|</AddressItem>
              <AddressItem>Location</AddressItem>
              <AddressItem>|</AddressItem>
              <AddressItem>On shopboon since 2022</AddressItem>
            </AddressWrapper>
          </InfoWrapper>
        </MainInfoWrapper>
        <Button variant='outlined' color={`${colors.BASE_BLUE}`}>Edit</Button>
    </ShopDetails>
  </>
  )
}
export default StoreHeaderIndividual