import React from 'react'
import styled from 'styled-components';
import {FiShoppingCart} from "react-icons/fi";

const BasketWrapper = styled.div`
display: flex;
align-items:center;
justify-content: center;
`;
function Basket() {
  return (
    <BasketWrapper>
      <FiShoppingCart/>
    </BasketWrapper>
  )
}

export default Basket