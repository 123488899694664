import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import { BuyerHeader } from '../../../components/headers';
import { Home } from '../pages'


function BuyerRoutes() {
  return (<>
  <BuyerHeader/>
    <Routes>
      <Route path="" element={<Home/>}/>

      <Route path="*" element={(<Navigate to="/"/>)}/>
    </Routes>
  </>
  )
}

export default BuyerRoutes