import React from 'react'
import { Routes, Route, Navigate} from 'react-router-dom';
import { StoreHeader } from '../../../components/headers';

function SellerRoutes() {
  return (<>
    <StoreHeader/>
    <Routes>
      <Route path="" element={'SellerHomepage'}/>
      <Route path="dashboard" element={'Seller dashboard'}/>

      <Route path="*" element={(<Navigate to="/"/>)}/>
    </Routes>
  </>
  )
}

export default SellerRoutes