import { RESTRICTED_ACTION, ROLE, ROLE_ACTIONS_MAP } from '../constants/roles';

class RoleService {
  roles?: ROLE[];
  rolesWatcherCB?: ()=>void = () => null;

  setRoles = (roles: ROLE[]) => {
    this.roles = roles as ROLE[];
    if (this.rolesWatcherCB){
      this.rolesWatcherCB();
    }
  };

  checkIsAvailable = (action: RESTRICTED_ACTION) => {
    if (!this.roles) return false;
    let status = false;
    for (let role of this.roles){
      if (!status){
       status = Boolean(ROLE_ACTIONS_MAP[role as ROLE]?.includes(action));
      }
    }
    return status
  };

  addRolesWatcher = (cb:()=>void) => {
    this.rolesWatcherCB = cb;
  }
}

export const RoleMiddleware = new RoleService();
