const actions =  {
    GET_USER:'GET_USER',
    SET_USER:'SET_USER',
    USER_LOGIN:'USER_LOGIN',
    USER_LOGOUT:'USER_LOGOUT',
    USER_REGISTRATION: 'USER_REGISTRATION',
    REDIRECT: 'REDIRECT',
    REDIRECT_SUCCESS: 'REDIRECT_SUCCESS',
    RESTORE_PASSwORD: 'RESTORE_PASSwORD',
    SET_ERROR: 'SET_ERROR',
    REMOVE_ERROR: 'REMOVE_ERROR',
    GET_SELLER_PLANS: 'GET_SELLER_PLANS',
    SET_SELLER_PLANS: 'SET_SELLER_PLANS',
}

export default actions