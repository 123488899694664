import Actions from '../saga/actionTypes'
import { api } from '../../services/api.service';
import {AxiosResponse} from 'axios';

interface IRedirectState {
    active: boolean,
    to: string | null,
}

const defaultState: IRedirectState = {
    active: false,
    to: '',
}

export default function redirectReducer(state = defaultState, action: any) {
    switch(action.type) {
        case Actions.REDIRECT:{
            return {active: true, to: action.url}
        }
        case Actions.REDIRECT_SUCCESS:{
            return defaultState
        }
}
    return state
}

export const redirect = (url: string | null) => ({type: Actions.REDIRECT, url});
export const redirectSuccess = () => ({type: Actions.REDIRECT_SUCCESS});

