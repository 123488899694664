import React from 'react';
import styled from 'styled-components';
import * as color from '../../common/constants/colors'
import * as sizes from '../../common/constants/sizes'
import { Link } from 'react-router-dom';
import StoreHeaderBussiness from './storeHeaderBussiness';
import StoreHeaderIndividual from './storeHeaderIndividual';
import { MainLogo } from '../UI/mainLogo';
import DefaultHeader from './defaultHeader';

const HeaderWrapper = styled.div`
color: ${color.BASE_BLACK};
height: ${sizes.APP_HEADER_HEIGHT_XL}px;
display: flex;
align-items: center;
box-shadow: 0px 1px 0px 0px #f3f3f3;
`;

function StoreHeader() {
  const isBussines = false
  return (<>
    <DefaultHeader/>
    {isBussines ? <StoreHeaderBussiness/> : <StoreHeaderIndividual/>} 
  </>
  )
}
export default StoreHeader