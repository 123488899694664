import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { StoreHeader } from '../../../../components/headers'
import {Button, Input} from '../../../../components/UI';
import * as colors from '../../../../common/constants/colors'
import { userRestoreAccess } from '../../../../common/store/reducers/user';
import { useDispatch } from 'react-redux';
import { validateValue } from '../../../../common/helpers';
import { validateEmail, validatePhone } from '../../../../common/helpers/validation';

const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const Title = styled.h1`
font-size: 42px;
font-weight: 600;
padding-top: 30px;
`;

const Form = styled.form`
padding-top: 95px;
width: 590px;
`;

const InputZone = styled.div`
width: 100%;
box-sizing: border-box;
padding-bottom: 66px;
`;

const Label = styled.div`
font-size: 18px;
font-weight: 600;
padding-bottom: 40px; 
`;

const ForgorPassword = styled.div`
margin-top: 24px;
> * {
  text-decoration: none;
  color: ${colors.BASE_BLUE}
}
`;

const ButtonsGroup = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const SignInButtonText = styled.span`
font-size: 20px;
font-weight: 600;
`;

const CreateAccount = styled.div`
margin-top: 43px;
> * {
  font-size: 28px;
  font-weight: 600;
  text-decoration: none;
  color: ${colors.BASE_BLUE}
}
`;

const InputToggleButton = styled.div`
position: absolute;
top: 0px;
right: 0px;
padding: 12px 20px;
`;

const ErrorLabel = styled.div`
color: red;
margin: 10px 15px;
font-weight: 500;
`

function Restore() {
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState('')
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [code, setCode] = useState('');
  const [restoreType, setRestoreType] = useState('');
  const [progressStep, setProgress] = useState(0);

  useEffect(()=>{
    setEmailError('');
    setPhoneError('');
    setRestoreType('');
  }, [userInput])

  useEffect(()=>{
    if (!emailError){
      setEmail(userInput)
      setRestoreType('email')
    } else if (!phoneError){
      setPhone(userInput)
      setRestoreType('sms')
    }
  }, [emailError, phoneError])



  useEffect(()=>{
    console.log({
      restoreType,
      email: {
        email,
        emailError,
      },
      phone: {
        phone,
        phoneError
      }
  });
    
    if (restoreType && ((email && !emailError) || (phone && !phoneError)) ){
      dispatch(userRestoreAccess(restoreType, email, phone, code))
      setProgress(progressStep+1)
  }}, [restoreType])

  const restorePassword = async (e: any) => {
    e.preventDefault();
    await validateValue(userInput, validateEmail, (e)=>setEmailError(e))
    await validateValue(userInput, validatePhone, (e)=>setPhoneError(e))

    if (code){
      dispatch(userRestoreAccess(restoreType, email, phone, code))
    }
  }

  return (
    <>
    {/* <StoreHeader/> */}
    <Wrapper>
      <Title>Restore Access</Title>
      <Form onSubmit={restorePassword}>
        <InputZone>
          <Label>Email or Phone number</Label>
          <Input placeholder={'Email or Phone number'} onChange={(e)=>{setUserInput(e.target.value)}}/>
          <ErrorLabel>{(emailError && email) || (phoneError && phone) ? <>Invalid email or phone number</> : <></>}</ErrorLabel>
        </InputZone>
        {progressStep > 0 ? <>
          <InputZone>
          <Label>Restore code</Label>
          <Input placeholder={'Code'} onChange={(e)=>{setCode(e.target.value)}}/>
        </InputZone>
        </> : <></>}
        <ButtonsGroup>
          <Button margin-top={'115px'} magrin-bottom={'43px'} color={colors.BASE_BLUE} width='100%' height='85px' radiusValue={'43px'} onClick={restorePassword}>
            <SignInButtonText>Restore</SignInButtonText>
          </Button>
        </ButtonsGroup>  
      </Form>
    </Wrapper>
    </>
  )
}

export default Restore