import Actions from '../saga/actionTypes'
import { api } from '../../services/api.service';
import {AxiosResponse} from 'axios';
import { useDispatch } from 'react-redux';

const defaultState: Record<string | number, string> = {
}

export default function errorsReducer(state = defaultState, action: any) {
    switch(action.type) {
        case Actions.SET_ERROR:{
            const timeStamp = Date.now();
            return {...state, [timeStamp]: action.message}
        }
        case Actions.REMOVE_ERROR:{
            const {id} = action;
            if (state[id]){
                delete state[id]
            }
            return {...state}
        }
}
    return state
}

export const setError = (message: string) => ({type: Actions.SET_ERROR, message});
export const removeError = (id: number | string) => ({type: Actions.REMOVE_ERROR, id});

