import React, { ReactElement } from 'react'
import styled from 'styled-components';
import * as colors from '../../common/constants/colors'

const UserAvatar = styled.div`
width: 100px;
height: 100px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
font-size: 32px;
font-weight: 500;
background-color: ${colors.BASE_GRAY_BACKGROUND}
`;

function LargeUserImageText(props: {children: any}) {
  return (
    <UserAvatar>{props.children}</UserAvatar>
  )
}

export default LargeUserImageText