import React from 'react'
import { Carousel } from '../../../../components/carousel'
function Home() {
  return (
    <div>
    <div>Categories</div>
    <div>Carusel</div>
    <div>Main content</div>
    </div>
  )
}

export default Home